import './style.scss';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import LineDrawing from '../DrawSvg';

gsap.registerPlugin(ScrollTrigger);

const Draw = () => {
  const {t} = useTranslation()
  const icons = t('draw.icons');

  let drawWrapper = '+=3500';
  useEffect(() => {
    if (window.innerWidth < 1100) {
      drawWrapper = '+=1800';
    }
    gsap.to('.draw__wrapper', {
      scrollTrigger: {
        trigger: '.draw',
        start: '0% 0%',
        end: `${
          window.innerHeight < 450 ||
          (window.innerWidth < 900 && window.innerHeight > 950)
            ? '+=10'
            : drawWrapper
        }`,
        scrub: 1,
        pin: true,
      },
    });
    gsap.to('.draw-bg', {
      scrollTrigger: {
        trigger: '.big-identity',
        start: `${window.innerWidth > 1100 ? '-125% 0%' : '-140% 0%'}`,
        end: '+=500',
        scrub: true,
      },
      opacity: 0,
    });
    gsap.to('.icons-block', {
      scrollTrigger: {
        trigger: '.big-identity',
        start: `${window.innerWidth > 1100 ? '-70% 0%' : '-100% 0%'}`,
        end: `${window.innerWidth > 1100 ? '-40% 0%' : '-70% 0%'}`,
        scrub: true,
      },
      stagger: `${window.innerWidth > 1100 ? 0.3 : 0}`,
      opacity: 1,
    });
    gsap.to('.icons-title', {
      scrollTrigger: {
        trigger: '.big-identity',
        start: `${window.innerWidth > 1100 ? '-70% 0%' : '-100% 0%'}`,
        end: `${window.innerWidth > 1100 ? '-40% 0%' : '-50% 0%'}`,
        scrub: true,
      },
      opacity: 1,
    });

    let posXIcon = 85 + 'rem';
    let posYIcon = 305 + 'rem';
    let opacityIcon = 1;

    if (window.innerWidth < 1100 && window.innerWidth > 551) {
      posXIcon = 0;
      posYIcon = 0;
      opacityIcon = 0;
    }

    let calculateY = -0.203125;

    if (window.innerWidth < 550) {
      posXIcon = 2500 + 'rem';
      posYIcon = calculateY + 375;
    }

    if (window.innerWidth < 1050 && window.innerHeight > 1350) {
      posXIcon = 200 + 'rem';
      posYIcon = 400 + 'rem';
    }

    if (
      window.innerWidth < 1150 &&
      window.innerWidth > 991 &&
      window.innerHeight > 850
    ) {
      posXIcon = 90 + 'rem';
    }

    gsap.to('.svg-icon', {
      scrollTrigger: {
        trigger: '.big-identity',
        start: `${window.innerWidth > 1100 ? '-105% 0%' : '-130% 0%'}`,
        end: `${window.innerWidth > 1100 ? '+=900' : '+=550'}`,
        scrub: true,
      },
      x: posXIcon,
      y: posYIcon,
      width: `${window.innerWidth > 1100 ? 130 + 'rem' : 450 + 'rem'}`,
      height: `${window.innerWidth > 1100 ? 150 + 'rem' : 450 + 'rem'}`,
      opacity: opacityIcon,
    });
    gsap.to('.decor-draw', {
      scrollTrigger: {
        trigger: '.big-identity',
        start: '-115% 0%',
        end: '+=1500',
        scrub: true,
      },
      opacity: 1,
    });
  }, []);

  return (
    <section className='draw'>
      {window.innerHeight > 1000 && window.innerWidth < 1100 ? null : (
        <>
          <LineDrawing
            pathSelector='.st0'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '100% 100%' : '-30% 0%'}
          />

          <LineDrawing
            pathSelector='.path1'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />

          <LineDrawing
            pathSelector='.path2'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />

          <LineDrawing
            pathSelector='.path3'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />

          <LineDrawing
            pathSelector='.path4'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />

          <LineDrawing
            pathSelector='.path5'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />

          <LineDrawing
            pathSelector='.path6'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />

          <LineDrawing
            pathSelector='.path7'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />

          <LineDrawing
            pathSelector='.path9'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />

          <LineDrawing
            pathSelector='.path10'
            initialDashArray='0px 1400px'
            finalDashArray='1500px 1300px'
            triggerSelector='.draw__wrapper'
            start={window.innerWidth > 1100 ? '110% 50%' : '80% 50%'}
            end={window.innerWidth > 1100 ? '250% 50%' : '120% 50%'}
          />
        </>
      )}

      <div className='draw__wrapper'>
        <img
          className='draw-bg'
          src='/img/draw-bg.jpg'
          alt=''
        />
        <div className='svg-wrap'>
          <svg
            className='svg-icon'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1440 920'
            fill='none'>
            <g>
              <path
                className='st0'
                d='M584.938 550.5V269.28H624.078V205.01H670.528V141.32H769.508V205.01H815.997V269.28H855.028V550.5'
                stroke='#DF3C17'
              />
              <g className='path-wrapper'>
                <path
                  className='path1'
                  d='M528.981 685.771L329.981 486.771L357.661 459.101L312.211 413.651L345.061 380.801L300.031 335.771L370.011 265.781L415.041 310.811L447.921 277.941L493.361 323.391L520.971 295.791L719.971 494.791'
                  stroke='#DF3C17'
                />
                <path
                  className='path2'
                  d='M584.937 820.842H303.507V781.702H239.227V735.242H175.547V636.272H239.227V589.782H303.507V550.742H584.937'
                  stroke='#DF3C17'
                />
                <path
                  className='path3'
                  d='M719.998 876.781L520.998 1075.78L493.318 1048.11L447.878 1093.56L415.028 1060.71L369.998 1105.74L300.008 1035.75L345.038 990.721L312.168 957.851L357.618 912.401L330.008 884.801L529.018 685.801'
                  stroke='#DF3C17'
                />
                <path
                  className='path4'
                  d='M855.059 820.84V1102.27H815.919V1166.54H769.469V1230.22H670.489V1166.54H623.999V1102.27H584.969V820.84'
                  stroke='#DF3C17'
                />
                <path
                  className='path5'
                  d='M911.006 685.77L1110.01 884.77L1082.33 912.45L1127.78 957.9L1094.93 990.75L1139.96 1035.78L1069.98 1105.76L1024.95 1060.73L992.066 1093.61L946.626 1048.16L919.016 1075.76L720.016 876.76'
                  stroke='#DF3C17'
                />
                <path
                  className='path6'
                  d='M855.062 550.711H1136.49V589.851H1200.77V636.301H1264.45V735.281H1200.77V781.771H1136.49V820.801H855.062'
                  stroke='#DF3C17'
                />
                <path
                  className='path7'
                  d='M720 494.763L919 295.763L946.68 323.443L992.12 277.992L1024.97 310.842L1070 265.812L1139.99 335.802L1094.96 380.832L1127.83 413.703L1082.38 459.152L1109.99 486.753L910.98 685.753'
                  stroke='#DF3C17'
                />
                <path
                  className='path8'
                  d='M910.975 685.77L1046.01 820.8H855.025V1011.78L719.995 876.76L584.935 1011.78V820.8H393.945L528.975 685.77L393.945 550.71H584.935V359.73L719.995 494.79L855.025 359.73V550.71H1046.01L910.975 685.77Z'
                  stroke='#DF3C17'
                />
                <path
                  className='path9'
                  d='M528.977 685.77H910.977'
                  stroke='#DF3C17'
                />
                <path
                  className='path10'
                  d='M720 876.763V494.793'
                  stroke='#DF3C17'
                />
              </g>
            </g>
            <defs>
              <clipPath id='clip0_12272_114'>
                <rect fill='white' />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className='icons'>
          <div className='icons__wrapper'>
            <div className='icons-title'>
              <h2>{t("draw.title")}</h2>
              <p>{t("draw.text")}</p>
            </div>
            {icons.map((item, index) => (
              <div
                className='icons-block'
                key={index}>
                <img
                  src={item.img}
                  alt='icon'
                />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='decor-draw'></div>
      </div>
    </section>
  );
};

export default Draw;
