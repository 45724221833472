import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'uk',
    returnObjects: true,
    detection: {
      order: ['path', 'querystring', 'localStorage', 'cookie', 'navigator'],
      lookupQuerystring: 'lng',
      lookupFromPathIndex: 0,
      caches: ['localStorage', 'cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    // debug: true,
  });

i18n.on('languageChanged', (lng) => {
  const currentPath = window.location.pathname;
  let newPath = currentPath;

  if (lng === 'en') {
    if (!currentPath.startsWith('/en')) {
      newPath = `/en${currentPath}`;
    }
  } else {
    newPath = currentPath.replace(/^\/en/, '');
  }

  if (newPath !== currentPath) {
    history.push(newPath);
  }
});


const pathLang = window.location.pathname.split('/')[1];
if (['en', 'uk'].includes(pathLang)) {
  i18n.changeLanguage(pathLang);
} else {
  i18n.changeLanguage('uk');
}

export default i18n;
