import './style.scss';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const images = [
  '/img/identity/big2.jpg',
  '/img/identity/big5.jpg',
  '/img/identity/big3.jpg',
  '/img/identity/big4.jpg',
  '/img/identity/big1.jpg',
  '/img/identity/big6.jpg',
];

const IdentityBig = () => {
  const {t} = useTranslation()

  const gridRef = useRef(null);
  let gridWrapperEnd = '+=1400';

  useEffect(() => {
    if (window.innerWidth > 1100) {
      const grid = gridRef.current;
      const gridWrap = grid.querySelector('.grid-wrap');
      const gridItems = grid.querySelectorAll('.grid__item');
      const gridItemsInner = document.querySelectorAll('.grid__item-inner');

      const timeline = gsap.timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          trigger: '.big-identity-wrap',
          start: '10% bottom+=5%',
          end: `${
            window.innerWidth > 768 ? 'bottom top-=5%' : 'bottom top-=90%'
          }`,
          scrub: true,
        },
      });

      gsap.to('.grid-wrapper', {
        scrollTrigger: {
          trigger: '.big-identity-wrap',
          start: '-5% 10%',
          end: gridWrapperEnd,
          scrub: true,
          pin: true,
        },
      });

      gsap.to(gridItems[0], {
        scrollTrigger: {
          trigger: '.big-identity',
          start: '15% 0%',
          scrub: true,
        },
        x: -300,
        y: 1000,
        z: 1500,
        immediateRender: false,
      });

      gsap.to(gridItems[1], {
        scrollTrigger: {
          trigger: '.big-identity',
          start: '15% 0%',
          scrub: true,
        },
        z: 1500,
        y: -1000,
        immediateRender: false,
      });

      gsap.to(gridItems[2], {
        scrollTrigger: {
          trigger: '.big-identity',
          start: '15% 0%',
          scrub: true,
        },
        z: 2500,
        y: 2000,
        immediateRender: false,
      });

      gsap.to(gridItems[3], {
        scrollTrigger: {
          trigger: '.big-identity',
          start: '15% 0%',
          scrub: true,
        },
        z: 300,
        immediateRender: false,
      });

      gsap.to(gridItems[4], {
        scrollTrigger: {
          trigger: '.big-identity',
          start: '20% 20%',
          end: 'bottom bottom',
          scrub: true,
        },
        y: -200 + 'rem',
        x: `${window.innerWidth > 1650 ? -20 : -17}`,
        scale: 3,
      });

      gsap.to(gridItems[5], {
        scrollTrigger: {
          trigger: '.big-identity',
          start: '15% 0%',
          scrub: true,
        },
        x: 100,
        y: 2300,
        z: 2500,
        immediateRender: false,
      });

      grid.style.setProperty('--grid-columns', '3');
      grid.style.setProperty('--perspective', '1500px');

      timeline
        .set(gridItems, {
          transformOrigin: '50% 0%',
          z: -3500,
          opacity: 0,
        })
        .to(
          gridItems,
          {
            rotationX: 0,
          },
          0
        )
        .to(
          gridWrap,
          {
            z: 3800,
          },
          0
        )
        .fromTo(
          gridItemsInner,
          {
            scale: 2,
          },
          {
            scale: 0.5,
          },
          0
        );

      gridItems.forEach((item) => {
        gsap.to(item, {
          scrollTrigger: {
            trigger: item,
            start: '60% 60%',
            end: '0% top',
            scrub: 2,
          },
          opacity: 1,
        });
      });
    }
  }, []);

  return (
    <section
      className='big-identity'
      id='identity'>
      <div className='container'>
        <h2>{t("identity.title")}</h2>
        <h3>{t("identity.text")}</h3>
        <div className='big-identity-wrap'>
          <div className='grid-wrapper'>
            <div
              className='grid'
              ref={gridRef}>
              <div className='grid-wrap'>
                {images.map((src, index) => (
                  <div
                    className='grid__item'
                    key={index}>
                    <div
                      className='grid__item-inner'
                      style={{ backgroundImage: `url(${src})` }}></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IdentityBig;
