import './style.scss';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useState } from 'react';

gsap.registerPlugin(ScrollTrigger);

const Symbols = () => {
  const {t} = useTranslation()
  const images = t('symbols.images');

  const wheelRef = useRef(null);
  const imagesRef = useRef([]);
  const [radiusSlider] = useState(
    window.innerWidth < 550 ? 0.5 : 1.4
  );

  useEffect(() => {
    if (
      (window.innerWidth < 770 && window.innerHeight > 1000) ||
      window.innerHeight < 450
    ) {
      return;
    } else {
      const setup = () => {
        const wheel = wheelRef.current;
        if (!wheel) return;

        let totalOne = 0;

        if (window.innerWidth < 550) {
          totalOne = 3.5;
        }

        const radius = wheel.offsetWidth / radiusSlider;
        const center = wheel.offsetWidth / 2;
        const total = imagesRef.current.length + totalOne;
        const slice = (2 * Math.PI) / total;

        imagesRef.current.forEach((item, i) => {
          if (!item) return;

          const angle = i * slice;
          const x = center + radius * Math.sin(angle);
          const y = center - radius * Math.cos(angle);

          gsap.set(item, {
            rotation: `${angle}_rad`,
            xPercent: -50,
            yPercent: -50,
            x,
            y,
          });
        });
      };

      setup();

      let topTriggerPosition = 100;

      let prev = document.querySelector('.prev');

      if (window.innerWidth < 1100) {
        topTriggerPosition = 100;
      }

      if (
        window.innerWidth < 1100 &&
        window.innerWidth > 991 &&
        window.innerHeight > 599
      ) {
        topTriggerPosition = 0;
      }

      const scrollTween = gsap.to(wheelRef.current, {
        rotate: () => (window.innerWidth < 550 ? -189.5 : -300),
        ease: 'none',
        duration: 1,
        scrollTrigger: {
          trigger: '.symbols',
          start: () => `top ${prev.clientHeight + topTriggerPosition}`,
          end: () => (window.innerWidth < 600 ? '+=1500' : '+=3500'),
          scrub: 2,
          pin: '.symbols__wrapper',
          invalidateOnRefresh: true,
        },
      });

      window.addEventListener('resize', () => {
        ScrollTrigger.refresh();
      });

      return () => {
        scrollTween.scrollTrigger.kill();
      };
    }
  }, [radiusSlider, imagesRef.current.length]);

  return (
    <>
      <h2 className='symbols-title'>{t("symbols.title")}</h2>
      <h3 className='symbols-subtitle'>{t("symbols.text")}</h3>
      <div className='prev'></div>
      <section
        className='symbols'
        id='brand-strategy'>
        <div className='symbols__wrapper'>
          {(window.innerWidth < 770 && window.innerHeight > 1000) ||
          window.innerHeight < 450 ? (
            <div className='slide-section'>
              <div
                className='slide-wrap'
                ref={wheelRef}>
                {images.map((item, index) => (
                  <div
                    className='slide-card'
                    ref={(el) => (imagesRef.current[index] = el)}
                    key={index}>
                    <img
                      src={item.src}
                      alt={item.alt}
                    />
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className='slider-section'>
              <div
                className='wheel'
                ref={wheelRef}>
                {images.map((src, index) => (
                  <div
                    className='wheel__card'
                    ref={(el) => (imagesRef.current[index] = el)}
                    key={index}>
                    <img
                      src={src.src}
                      alt={`img-${index}`}
                    />
                    <p>{src.text}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Symbols;
