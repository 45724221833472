import './style.scss';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

const Footer = () => {
  const {t} = useTranslation()

  return (
    <footer className='footer'>
      <img
        className='footer-icon'
        src='/img/download-svg.svg'
        alt=''
      />
      <div className='container'>
        <div className='footer__wrapper'>
          <div className='footer-copy'>
            © Copyright 2024 by OCB Agency. All Rights Reserved.
          </div>
          <nav className='footer__menu'>
            <ul>
              <li>
                <a href='#research'>{t("menu.item1")}</a>
              </li>
              <li>
                <a href='#identity'>{t("menu.item2")}</a>
              </li>
              <li>
                <a href='#team'>{t("menu.item3")}</a>
              </li>
            </ul>
            <LanguageSelector />
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
