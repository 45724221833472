import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';

const MetaTags = ({ lang }) => {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language || "uk";

  return (
    <Helmet>
      <html lang={currentLang} />
      <title>{t("meta.title")}</title>
      <meta name="description" content={t("meta.description")} />
      <meta property="og:description" content={t("meta.description")} />
      <meta property="og:url" content={t("meta.url")} />
      <link rel="canonical" href={t("meta.url")} />
    </Helmet>
  );
};

export default MetaTags;
