import { useRef, useEffect } from 'react';
import AnimatedNumber from '../AnimatedNumber';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './style.scss';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  const {t} = useTranslation()
  const researchList = t('hero.research.list');

  const blocksRef = useRef([]);

  useEffect(() => {
    blocksRef.current.forEach((block, index) => {
      const imgActive = block.querySelector('.research-block__img-active');

      gsap.fromTo(
        imgActive,
        { opacity: 0 },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: block,
            start: 'top center',
            end: 'bottom center',
            toggleActions: 'play none none reverse',
          },
        }
      );
    });
  }, []);

  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='hero__wrapper'>
            <div className='hero-title'>
              <h1>{t("hero.title")}</h1>
              <img
                src='/img/hero-svg.svg'
                alt=''
              />
              <p>{t("hero.description")}</p>
            </div>
            <div className='hero-partners'>
              <img
                src='/img/partners/partners-white.png'
                alt=''
              />
              <p>{t("hero.financed_by")}</p>
            </div>
          </div>
        </div>
      </section>

      <section
        className='research'
        id='research'>
        <div className='container'>
          <div className='research__wrapper'>
            <h2 className='research-title'>{t("hero.research.title")}</h2>
            <p className='research-text'>{t("hero.research.text")}</p>
            <ul className='research-blocks'>
              {researchList.map((item, index) => (
                <li
                  className='research-block'
                  key={index}
                  ref={(el) => (blocksRef.current[index] = el)}>
                  <div className='research-block__img'>
                    <img
                      className='research-block__img-dis'
                      src='/img/research-icon.svg'
                      alt='icon'
                    />
                    <img
                      className='research-block__img-active'
                      src='/img/research-icon-active.svg'
                      alt='icon'
                    />
                  </div>
                  <div className='research-block__text'>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className='numbers'>
            <ul className='numbers__wrapper'>
              <li className='numbers-block'>
                <h3>{t("hero.research.numbers.title1")}</h3>
                <AnimatedNumber value='1174' />
              </li>
              <li className='numbers-block'>
                <h3>{t("hero.research.numbers.title2")}</h3>
                <AnimatedNumber value='58.6%' />
              </li>
              <li className='numbers-block'>
                <h3>{t("hero.research.numbers.title3")}</h3>
                <AnimatedNumber value='688' />
              </li>
              <li className='numbers-block'>
                <h3>{t("hero.research.numbers.title4")}</h3>
                <AnimatedNumber value='32:46' />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
