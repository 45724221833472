import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const {i18n} = useTranslation()
  const languages = [
    {code: 'en', name: 'EN'},
    {code: 'uk', name: 'UA'}
  ]
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  return (
    <div className="language-selector">
      {languages
        .filter((lng) => lng.code !== i18n.language)
        .map((lng) => (
          <button
            key={lng.code}
            onClick={() => changeLanguage(lng.code)}
          >
            {lng.name}
          </button>
        ))}
    </div>
  );
};

export default LanguageSelector;
