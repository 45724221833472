import './style.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

const Banner = () => {
  useEffect(() => {
    if (window.innerWidth > 1100) {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: '.banner',
          start: 'top top',
          end: '+=1500',
          scrub: true,
          pin: true,
        },
      });

      timeline
        .to('.banner-second', {
          clipPath: 'inset(0% 0 0% 0)',
          duration: 1,
        })

        .to(
          '.banner-third',
          {
            clipPath: 'inset(0% 0 0% 0)',
            duration: 1,
          },
          '-=0'
        );
    }
  }, []);

  return (
    <>
      <section className='banner'>
        <div className='banner-desc'>
          <img
            className='bilbord'
            src='/img/bilbord/bilbord.jpg'
            alt=''
          />
          <div className='banner-wrapper'>
            <img
              className='banner-first'
              src='/img/bilbord/banner.png'
              alt=''
            />
            <img
              className='banner-second'
              src='/img/bilbord/banner2.png'
              alt=''
            />
            <img
              className='banner-third'
              src='/img/bilbord/banner3.png'
              alt=''
            />
          </div>
        </div>
        <div className='banner-mob'>
          <div className='container'>
            <div className='banner-mob__wrapper'>
              <div className='banner-mob__item'>
                <img
                  src='/img/bilbord/banner-mobile1.jpg'
                  alt=''
                />
              </div>
              <div className='banner-mob__item'>
                <img
                  src='/img/bilbord/banner-mobile2.jpg'
                  alt=''
                />
              </div>
              <div className='banner-mob__item'>
                <img
                  src='/img/bilbord/banner-mobile3.jpg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='identity'>
        <div className='container'>
          <div className='identity__wrapper'>
            <div className='identity-banner'>
              <img
                src='/img/identity/1.jpg'
                alt=''
              />
            </div>
            <div className='identity-banner'>
              <img
                src='/img/identity/2.jpg'
                alt=''
              />
            </div>

            <div className='identity-big'>
              <img
                src='/img/identity/3.jpg'
                alt=''
              />
            </div>
            <div className='identity-block'>
              <div className='identity-block__wrapper'>
                <div>
                  <img
                    src='/img/identity/4.jpg'
                    alt=''
                  />
                </div>
                <div>
                  <img
                    src='/img/identity/5.jpg'
                    alt=''
                  />
                </div>
              </div>
              <div className='identity-block__big'>
                <img
                  src='/img/identity/6.jpg'
                  alt=''
                />
              </div>
            </div>

            <div className='identity-block'>
              <div className='identity-block__wrapper'>
                <div>
                  <img
                    src='/img/identity/7.jpg'
                    alt=''
                  />
                </div>
                <div>
                  <img
                    src='/img/identity/8.jpg'
                    alt=''
                  />
                </div>
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/9.jpg'
                  alt=''
                />
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/10.png'
                  alt=''
                />
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/11.png'
                  alt=''
                />
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/12.png'
                  alt=''
                />
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/13.jpg'
                  alt=''
                />
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/14.png'
                  alt=''
                />
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/15.png'
                  alt=''
                />
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/16.jpg'
                  alt=''
                />
              </div>
              <div className='identity-block__big identity-block__big-rev'>
                <img
                  src='/img/identity/17.jpg'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
