import './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const EndPage = () => {
  const {t} = useTranslation()
  const team1List = t('team.team1.list');
  const team2List = t('team.team2.list');
  const address = t('team.address');

  const modalRef = useRef(null);
  const videoRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (event) => {
    event.stopPropagation();
    setIsModalOpen(true);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <div
          className='video-modal'
          onClick={closeModal}
          style={{ opacity: 1, pointerEvents: 'unset' }}
          ref={modalRef}>
          <iframe
            src='https://player.vimeo.com/video/1013157494?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1'
            frameBorder='0'
            allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
            title='Чернівці. Мозаїка культур.'></iframe>
          <div
            className='video-modal-close'
            onClick={closeModal}
            style={{ cursor: 'pointer' }}></div>
        </div>
      )}
      <section
        className='video'
        onClick={openModal}>
        <video
          className='poster'
          src='/img/video/video.mp4'
          autoPlay
          muted
          loop
          ref={videoRef}></video>
        <div className='play'>
          <img
            src='/img/video/play.svg'
            alt=''
          />
        </div>
      </section>
      <section
        className='team'
        id='team'>
        <div className='container'>
          <h2>{t("team.title")}</h2>
          <div className='team__wrapper'>
            <div className='team-block'>
              <h4>
                {t("team.team1.title.part1")} <span>{t("team.team1.title.part2")}</span>
              </h4>
              <ul>
                {team1List.map((member, index) => (
                  <li key={index}>
                    <h5>{member.title}</h5>
                    <p>{member.subtitle}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className='team-block'>
              <h4>
                {t("team.team2.title.part1")} <span>{t("team.team2.title.part2")}</span>
              </h4>
              <ul className='address'>
                {address.map((address, index) => (
                  <li key={index}>
                    <h5>{address.title}</h5>
                    <p>{address.subtitle}</p>
                  </li>
                ))}
              </ul>
              <ul>
                {team2List.map((member, index) => (
                  <li key={index}>
                    <h5>{member.title}</h5>
                    <p>{member.subtitle}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='team-moderator'>
            <h3>
              {t("team.moderator.title.part1")} <span>{t("team.moderator.title.part2")}</span>
            </h3>
            <div>
              <h5>{t("team.moderator.name")}</h5>
              <p>{t("team.moderator.position")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className='download'>
        <div className='container'>
          <h3>{t("documents.title")}</h3>
          <div className='download__wrapper'>
            <a
              className='download-block'
              href='/img/Результати_якісного_дослідження_міста_Чернівці_2024.pdf'
              download>
              <div className='download-text'>
                <img
                  className='download-text__file'
                  src='/img/download.svg'
                  alt='file icon'
                />
                <p>{t("documents.item1")}</p>
                <img
                  className='download-text__icon'
                  src='/img/download-svg.svg'
                  alt='ornament icon'
                />
              </div>
              <div className='download-btn'>
                <div className='download-btn__arrow'>
                  <svg
                    className='first-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                  <svg
                    className='second-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              className='download-block'
              href='/img/Бренд_та_комунікаційна_стратегія_Чернівці_2024_1.pdf'
              download>
              <div className='download-text'>
                <img
                  className='download-text__file'
                  src='/img/download.svg'
                  alt='file icon'
                />
                <p>{t("documents.item2")}</p>
                <img
                  className='download-text__icon'
                  src='/img/download-svg.svg'
                  alt='ornament icon'
                />
              </div>
              <div className='download-btn'>
                <div className='download-btn__arrow'>
                  <svg
                    className='first-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                  <svg
                    className='second-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              className='download-block'
              href='/img/Результати_аудиту_міста_та_кількісного_дослідження_міста_Чернівці.pdf'
              download>
              <div className='download-text'>
                <img
                  className='download-text__file'
                  src='/img/download.svg'
                  alt='file icon'
                />
                <p>{t("documents.item3")}</p>
                <img
                  className='download-text__icon'
                  src='/img/download-svg.svg'
                  alt='ornament icon'
                />
              </div>
              <div className='download-btn'>
                <div className='download-btn__arrow'>
                  <svg
                    className='first-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                  <svg
                    className='second-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </a>
            <a
              className='download-block'
              href='/img/GIZ-Сhernivtsi-BrandBook.pdf'
              download>
              <div className='download-text'>
                <img
                  className='download-text__file'
                  src='/img/download.svg'
                  alt='file icon'
                />
                <p>{t("documents.item4")}</p>
                <img
                  className='download-text__icon'
                  src='/img/download-svg.svg'
                  alt='ornament icon'
                />
              </div>
              <div className='download-btn'>
                <div className='download-btn__arrow'>
                  <svg
                    className='first-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                  <svg
                    className='second-arrow'
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='24'
                    viewBox='0 0 16 24'
                    fill='none'>
                    <path
                      d='M7 0C7.24165 0 7.44788 0.0976017 7.61869 0.292805C7.78949 0.488008 7.8749 0.723692 7.8749 0.999856V16.5862L12.5031 11.2893C12.6716 11.0967 12.879 11.0003 13.1251 11.0003C13.3757 11.0003 13.5842 11.0954 13.7505 11.2855C13.9168 11.4755 14 11.7138 14 12.0002C14 12.2815 13.9157 12.5184 13.7471 12.7111L7.62205 19.711C7.45348 19.9037 7.24613 20 7 20C6.75387 20 6.54652 19.9037 6.37796 19.711L0.25285 12.7111C0.0842835 12.5184 0 12.2815 0 12.0002C0 11.7138 0.0831635 11.4755 0.24949 11.2855C0.415817 11.0954 0.624285 11.0003 0.874895 11.0003C1.12103 11.0003 1.32837 11.0967 1.49694 11.2893L6.1251 16.5862V0.999856C6.1251 0.723692 6.21051 0.488008 6.38132 0.292805C6.55212 0.0976017 6.75835 0 7 0Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className='partners'>
        <div className='container'>
          <img
            src='/img/partners/partners.png'
            alt='partners'
          />
          <p>{t("partners.text1")}</p>
          <p>{t("partners.text2")}</p>
        </div>
      </section>
      <section className='create'>
        <div className='container'>
          <div className='create__wrapper'>
            <div className='create-block'>
              <h2>{t("footer.title")}</h2>
              <a
                href='https://t.me/kirill_onatskiy'
                rel="noopener noreferrer"
                target='_blank'
                className='create-block__button'>
                {t("footer.button")}
              </a>
            </div>
            <div className='create-block'>
              <div className='contacts'>
                <h4>{t("footer.contacts")}</h4>
                <ul className='contacts-menu'>
                  <li className='contacts-menu__item'>
                    <a
                      className='contants-link'
                      href='https://t.me/kirill_onatskiy'
                      rel="noopener noreferrer"
                      target='_blank'>
                      <span className='contacts-link__img'>
                        <img
                          src='/img/socials/telegram.svg'
                          alt='telegram'
                        />
                      </span>
                      <p className='contacts-link__text'>
                        t.me/kirill_onatskiy
                      </p>
                    </a>
                  </li>
                  <li className='contacts-menu__item'>
                    <a
                      className='contants-link'
                      href='mailto:hello@ocb.agency'>
                      <div className='contacts-link__img'>
                        <img
                          src='/img/socials/email.svg'
                          alt='email'
                        />
                      </div>
                      <p className='contacts-link__text'>hello@ocb.agency</p>
                    </a>
                  </li>
                  <li className='contacts-menu__item'>
                    <a
                      href='https://www.google.com/maps/place/%D1%83%D0%BB.+%D0%9C%D0%B8%D1%85%D0%B0%D0%B9%D0%BB%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F,+12,+%D0%96%D0%B8%D1%82%D0%BE%D0%BC%D0%B8%D1%80,+%D0%96%D0%B8%D1%82%D0%BE%D0%BC%D0%B8%D1%80%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D0%B8%D0%BD%D0%B0,+10001/@50.2554754,28.6648948,16z/data=!3m1!4b1!4m6!3m5!1s0x472c6496e81f77bf:0xd6929a5ae98aba82!8m2!3d50.2554754!4d28.6648948!16s%2Fg%2F1tfbghb6?entry=ttu'
                      rel="noopener noreferrer"
                      target='_blank'
                      className='contants-link'>
                      <div className='contacts-link__img'>
                        <img
                          src='/img/socials/marker.svg'
                          alt='marker'
                        />
                      </div>
                      <p className='contacts-link__text'>
                        Mykhailivska 12, Str. Zhytomyr
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='social'>
                <h4>{t("footer.social_media")}</h4>
                <ul>
                  <li>
                    <a
                      className='social-link'
                      href='https://www.instagram.com/hello.ocb/'
                      rel="noopener noreferrer"
                      target='_blank'>
                      <img
                        src='/img/socials/instagram.svg'
                        alt='instagram'
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className='social-link'
                      href='https://www.linkedin.com/company/ocb-design-and-strategy/about/'
                      rel="noopener noreferrer"
                      target='_blank'>
                      <img
                        src='/img/socials/linkedin.svg'
                        alt='linkedin'
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EndPage;
